<!-- =========================================================================================
  File Name: UserSidebar.vue
  Description: User sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
========================================================================================== -->


<template>
  <vs-sidebar v-if="typeof this.data.user_id !== 'undefined'" click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <!-- HEADER -->
    <div v-if="typeof this.data.user_id !== 'undefined'" class="bg-light flex items-center justify-between p-4 m-0">
      <user-avatar class="p-6" :key="'sidebar_avatar_'+this.data.user_id+'_v_'+this.incrementedAvatarVersion" :user="this.data" size="large" />
      <div>
        <strong>{{ first_name + ' ' + last_name | capitalize }}</strong>
        <h4 v-if="!currentUserProfile">{{ $t('userEdition') }}</h4>
        <h4 v-else>{{ $t('userSelfEdition') }}</h4>
      </div>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="m-0"/>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="flex flex-wrap mb-4 justify-between px-6 mt-2">
        <template template v-if="!isNaN(user_id)">
          
          <!-- ACCORDION -->
          <vs-collapse accordion type="margin" class="p-0 mt-0 w-full">

            <!---------------------------------------------------------------->
            <!-- AVATAR EDITION -->
            <vs-collapse-item v-if="currentUserProfile || currentUserCanManageUsers">
              <div slot="header">
                <vs-icon icon-pack="feather" icon="icon-image" />
                <span class="px-4">{{ this.$t('Avatar') }}</span>
              </div>

              <template v-if="avatarUploadInProgress">
                <p>{{ $t('treatmentInProgress') }}</p>
                <vs-progress class="mb-0 p-0" indeterminate color="primary"></vs-progress>
              </template>
              <div class="mt-0 mb-50 p-0 pb-50" >
                <vs-upload
                  :key="'upload_avatar_'+this.incrementedAvatarVersion"
                  automatic 
                  fileName="file[]" 
                  :action="`${imgBaseUrl}/upload.php?entity=user&type=avatar&id=${this.user_id}`" 
                  :text="$t('choseImage')"
                  limit="1"
                  multiple="false"
                  accept=".jpg, .png"
                  @on-success="avatarUploadSuccess" 
                  @on-error="avatarUploadError" 
                  @change="avatarUploadStarted"
                />
              </div>
            </vs-collapse-item>

            <!---------------------------------------------------------------->
            <!-- PROFILE EDITION -->
            <vs-collapse-item>
              <div slot="header">
                <vs-icon icon-pack="feather" icon="icon-user" />
                <span class="px-4">{{ this.$t('userInformations') }}</span>
              </div>

              <!-- first_name -->
              <vs-input @keydown.enter="prepareForm" @blur="prepareForm" 
                :label="$t('userFirstName')" class="mt-2 w-full"
                v-model="new_first_name" 
                name="first_name" 
              />
              <!-- last_name -->
              <vs-input @keydown.enter="prepareForm" @blur="prepareForm" 
                :label="$t('userLastName')" class="mt-2 w-full"
                v-model="new_last_name" 
                name="last_name" 
              />
              <!-- user_email -->
              <vs-input disabled @keydown.enter="prepareForm" @blur="prepareForm" 
                :label="$t('userEmail')" class="mt-2 w-full"
                v-model="new_user_email" 
                name="user_email" 
                :danger="(!new_user_email_is_valid || !new_user_email_is_available) && new_user_email!==''" 
                :danger-text="$t('valueIsNotValid')"
                val-icon-pack="feather"
                val-icon-danger="icon-x"
              />
              <!-- user lang -->
              <div class="flex flex-col mt-2 w-full">
                <label>{{$t('language')}}</label>
                <div v-for="lng in this.$i18n.availableLocales" :key="lng">
                  <vs-radio v-model="new_user_lang" :vs-value="lng" class="mt-3" @input="prepareForm">
                    <span class="mr-2">{{$t(lng)}}</span>
                  </vs-radio>
                </div>

              </div>
            </vs-collapse-item>
            <!---------------------------------------------------------------->
            <template v-if="capsLoaded">
              <template v-if="this.$store.state.portal_slug !== '' && this.$store.state.current_user_portal_caps !== null">
                <!-- PORTAL CAPABILITIES (si current user can_portal_users manage) -->
                <div @click="capsOpened = !capsOpened">
                  <vs-collapse-item class="scrollable">
                    <div slot="header">
                      <vs-icon icon-pack="feather" icon="icon-toggle-right" />
                      <span class="px-4">{{ this.$t('portalCaps') }}</span>
                    </div>
                    
                    <!-- can_portal_projects_see -->
                    <vs-row v-if="!capsLoaded || !new_can_portal_projects_manage" class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="8">
                        {{$t('can_portal_projects_see')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
                        <vs-switch v-if="currentUserProfile" :disabled="true" class="role" style="width: 90px;" color="warning" v-model="new_can_portal_projects_see">
                          <span slot="on">{{$t('employee')}}</span>
                          <span slot="off">{{$t('guest')}}</span>
                        </vs-switch>
                        <vs-switch v-else :disabled="!currentUserCanManageUsers" class="role" style="width: 90px;" color="warning" v-model="new_can_portal_projects_see" @click="btn_disabled = false">
                          <span slot="on">{{$t('employee')}}</span>
                          <span slot="off">{{$t('guest')}}</span>
                        </vs-switch>
                      </vs-col>
                      <template v-if="currentUserCanManageUsers">
                        <vs-alert :active="new_can_portal_projects_see === true" color="warning" >
                          {{$t('can_portal_projects_see_alert_on')}}
                        </vs-alert>
                        <vs-alert :active="new_can_portal_projects_see === false" color="primary" >
                          {{$t('can_portal_projects_see_alert_off')}}
                        </vs-alert>
                      </template>
                    </vs-row>
                    
                    <!-- can_portal_projects_manage -->
                    <vs-row class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="8">
                        {{$t('can_portal_projects_manage')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
                        <vs-switch v-if="currentUserProfile" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_projects_manage"/>
                        <vs-switch v-else-if="!currentUserCanManageUsers" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_projects_manage"/>
                        <vs-switch v-else :disabled="!currentUserCanManageUsers" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_projects_manage" @click="prepareForm"/>
                      </vs-col>
                      <template v-if="currentUserCanManageUsers">
                        <vs-alert :active="new_can_portal_projects_manage" color="danger" >
                          {{$t('can_portal_projects_manage_alert')}}
                        </vs-alert>
                      </template>
                    </vs-row>
                    
                    <!-- can_portal_layers_manage -->
                    <vs-row class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="8">
                        {{$t('can_portal_layers_manage')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
                        <vs-switch v-if="currentUserProfile" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_layers_manage" />
                        <vs-switch v-else-if="!currentUserCanManageUsers" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_layers_manage"/>
                        <vs-switch v-else :disabled="!currentUserCanManageUsers" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_layers_manage" @click="prepareForm"/>
                      </vs-col>
                      <template v-if="currentUserCanManageUsers">
                        <vs-alert :active="new_can_portal_layers_manage" color="danger" >
                          {{$t('can_portal_layers_manage_alert')}}
                        </vs-alert>
                      </template>
                    </vs-row>
                    
                    <!-- can_portal_users_manage (si pas userr actif)-->
                    <vs-row class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="8">
                        {{$t('can_portal_users_manage')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
                        <vs-switch v-if="currentUserProfile" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_users_manage"/>
                        <vs-switch v-else-if="!currentUserCanManageUsers" :disabled="true" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_users_manage"/>
                        <vs-switch v-else :disabled="!currentUserCanManageUsers" color="danger" icon-pack="feather" vs-icon-on="icon-alert-triangle" vs-icon-off="icon-x" v-model="new_can_portal_users_manage" @click="prepareForm" />
                      </vs-col>

                      <template v-if="currentUserCanManageUsers">
                        <vs-alert :active="currentUserProfile" color="danger" >
                          {{$t('CanRemoveCapabilityYourself')}}.
                        </vs-alert>
                        <vs-alert :active="!currentUserProfile && new_can_portal_users_manage" color="danger" >
                          {{$t('can_portal_users_manage_alert')}}
                        </vs-alert>
                      </template>
                    </vs-row>
                    
                    <!-- can_portal_access_start_date -->
                    <vs-row class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="4" vs-align="center" >
                        {{$t('can_portal_access_start_date')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="8" vs-align="center" >
                        <flat-pickr v-if="currentUserProfile" :disabled="true" class="mt-2" v-model="new_can_portal_access_start_date" />
                        <flat-pickr v-else :disabled="!currentUserCanManageUsers || currentUserProfile" class="mt-2" v-model="new_can_portal_access_start_date" @blur="prepareForm" />
                      </vs-col>
                    </vs-row>
                    
                    <!-- can_portal_access_end_date -->
                    <vs-row class="mt-2" vs-w="12" vs-type="flex" vs-justify="space-around">
                      <vs-col vs-type="flex" vs-justify="default" vs-w="4" vs-align="center" >
                        {{$t('can_portal_access_end_date')}}
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="8" vs-align="center" >
                        <flat-pickr v-if="currentUserProfile" :disabled="true" class="mt-2" v-model="new_can_portal_access_end_date" />
                        <flat-pickr v-else :disabled="!currentUserCanManageUsers || currentUserProfile" class="mt-2" v-model="new_can_portal_access_end_date" @blur="prepareForm" />
                      </vs-col>
                    </vs-row>
                      
                  </vs-collapse-item>
                
                </div>
                <!---------------------------------------------------------------->


                <!-- PROJECTS -->
                <vs-collapse-item v-if="userProjectsLoaded && !capsOpened">
                  <div slot="header">
                    <vs-icon icon-pack="feather" icon="icon-box" />
                    <span class="px-4">{{ this.$t('userAccessibleProjects') }}</span>
                  </div>
                  <vs-alert :active="new_can_portal_projects_manage" color="danger" >
                    {{$t('canAccessToAllProjects')}}
                  </vs-alert>
                  <!-- liste les projets utilisant le user -->
                  <template v-if="userProjectsLoaded">
                    <h6 class="mt-6" v-if="new_can_portal_projects_manage">{{$t('memberOfProject')}}</h6>
                    <vs-alert color="warning" v-if="userProjects.length === 0" active="true">
                      {{ this.$t('noAllocation') }}
                    </vs-alert>
                    <template v-if="userProjects.length !== 0">
                      <vs-row :key="project.project_id" v-for="project in userProjects" class="px-2 mt-3 w-full">
                        <vs-col vs-type="flex" vs-justify="default" vs-w="6">
                          <div class="flex items-end leading-none py-1">
                            <vs-avatar size="40px" :src="`${imgBaseUrl}/project/cover/${project.project_id}/${thumbName}`" @click="gotoProject(project.project_slug)" />
                            <span class="project_title">{{ decodeURIComponent(project.project_nicename) }}</span>
                          </div>
                        </vs-col>
                        <!-- suppression du user sur le projet uniquement s'il n'est pas admin des users -->
                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">
                          <vs-button 
                            :disabled="project.can_project_users_manage !=='0'"
                            color="danger" type="line" icon-pack="feather" icon="icon-user-minus" @click="deleteProjectUserConfirm(project.project_id, user_id)">
                          </vs-button>
                        </vs-col>
                      </vs-row>
                    </template>
                  </template>
                </vs-collapse-item>
                <!---------------------------------------------------------------->

                <!-- ACTIVITY  / Disabled after security audit
                <vs-collapse-item v-if="userActivityLoaded && !capsOpened">
                  <div slot="header">
                    <vs-icon icon-pack="feather" icon="icon-activity" />
                    <span class="px-4">{{ this.$t('userActivity') }}</span>
                  </div>
                  <template>
                    <vs-table hoverFlat notSpacer search max-items="5" pagination stripe :data="userActivity">
                      <template slot="thead">
                        <vs-th sort-key="date">{{ this.$t('date') }}</vs-th>
                        <vs-th colspan="2">{{ this.$t('event') }}</vs-th>
                        <vs-th >{{ this.$t('details') }}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].timestamp">
                            {{ data[indextr].timestamp | longDate }}
                          </vs-td>
                          <vs-td :data="data[indextr].type">
                            <p>{{ $t(data[indextr].type) }}</p>
                          </vs-td>
                          <vs-td :data="data[indextr].type">
                            <p>{{ $t(data[indextr]) }}</p>
                            <vs-button @click="displayActivityPopup(data[indextr].sql_id)" radius color="success" type="flat" icon-pack="feather" icon="icon-info"></vs-button>
                          </vs-td>
                        </vs-tr>
                      </template>

                    </vs-table>
                  </template>

                </vs-collapse-item>
                -->
              </template>
            </template>
          </vs-collapse>
        </template>
      </div>
    </component>

    <!-- FOOTER -->
    
    <div class="bg-light" slot="footer">
      <vs-divider class="m-0"/>
      <vs-row class="p-4" vs-type="flex" vs-justify="space-around">
        <!-- enregistrer -->
        <vs-col class="px-2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-button color="primary" icon-pack="feather" icon="icon-save" :disabled="btn_disabled" @click="editUserSave" >{{this.$t('Save')}}</vs-button>
        </vs-col>
        <!-- annuler -->
        <vs-col class="px-2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-button size="default" color="warning" icon-pack="feather" icon="icon-x" @click="editUserCancel" >{{this.$t('Cancel')}}</vs-button>
        </vs-col>
        <!-- suppression si pas user actif-->
        <template v-if="!currentUserProfile">
          <vs-divider/>
          <vs-col class="px-2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button type="line" size="default" color="danger" icon-pack="feather" icon="icon-trash" @click="deleteUserConfirm" >{{this.$t('deleteUser')}}</vs-button>
          </vs-col>
        </template>
      </vs-row>

    </div>

  </vs-sidebar>
</template>

<script>
import axios from '@/axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  name: 'user-sidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar, flatPickr
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalUserProjects: 'getPortalUserProjects',
      // getPortalUserActivity: 'getPortalUserActivity', // disabled after security audit
      // getPortalUserActivityDetails: 'getPortalUserActivityDetails', // disabled after security audit
      updateUserIdentity: 'updateUserIdentity',
      deletePortalUserAllocation: 'deletePortalUserAllocation',
      deleteProjectUser: 'deleteProjectUser',
      updatePortalUserCaps: 'updatePortalUserCaps',
      getUserByEmail: 'getUserByEmail',
      imgBaseUrl : this.$appConfig.imgBaseUrl,

      // ROYBON / Liste des projets
      userProjects: {},
      userProjectsLoaded: false,

      // ROYBON / Liste des activités // disabled after security audit
      // userActivity: {},
      // userActivityLoaded: false,
      // popupActive: false,
      // popupActivityId: false,
      // popupContent: {},

      // ROYBON / Image du projet
      thumbName: 'thumb_200_200.jpg',

      // ROYBON / User informations
      user_id: '',
      first_name: '',
      last_name: '',
      user_email: '',

      // ROYBON / Changement de valeurs sur le profile
      new_first_name: '',
      new_last_name: '',
      new_user_email: '',
      new_user_email_is_valid: false,
      new_user_email_is_available: true,
      btn_disabled: true,
      incrementedAvatarVersion: 1,
      avatarUploadInProgress: false,
      user_lang: '',
      new_user_lang: '',

      // ROYBON / Capacités sur le portal
      can_portal_access_start_date: null,
      can_portal_access_end_date: null,
      can_portal_projects_see: null,
      can_portal_projects_manage: null,
      can_portal_layers_manage: null,
      can_portal_users_manage: null,

      // ROYBON / Changement de valeurs sur les capacités
      new_can_portal_access_start_date: null,
      new_can_portal_access_end_date: null,
      new_can_portal_projects_see: null,
      new_can_portal_projects_manage: null,
      new_can_portal_layers_manage: null,
      new_can_portal_users_manage: null,
      capsLoaded: false,
      capsOpened: false,

      // ROYBON / id du project dans lequel le user doit être dé-alloué
      projectWhereToRemoveUser: '',

      // ROYBON / Display
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },

  watch: {
    new_user_lang: function () {
      if (this.new_user_lang !== '') {
        this.prepareForm()
        if (this.currentUserProfile) this.$i18n.locale = this.new_user_lang
      }
    },
    new_user_email: function () {
      if (this.new_user_email.toLowerCase() !== this.user_email.toLowerCase()) this.checkNewUserEmail()
    },
    user_id: function () {
      this.getUserProjects()
      // this.getUserActivity() // disabled after security audit
    },
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { user_id        } = JSON.parse(JSON.stringify(this.data))
        this.user_id             = user_id

        const { first_name        } = JSON.parse(JSON.stringify(this.data))
        this.first_name             = first_name
        this.new_first_name         = this.capitalize(this.first_name)
        
        const { last_name        } = JSON.parse(JSON.stringify(this.data))
        this.last_name             = last_name
        this.new_last_name         = this.capitalize(this.last_name)
        
        const { user_email        } = JSON.parse(JSON.stringify(this.data))
        this.user_email             = this.sanitizeEmail(user_email)
        this.new_user_email         = this.user_email

        const { user_lang         } = JSON.parse(JSON.stringify(this.data))
        this.user_lang              = user_lang || 'fr'
        this.new_user_lang          = this.user_lang
        
        // IF CURRENT USER
        if (this.currentUserProfile) {
          if (this.capsLoaded) {
            this.can_portal_access_start_date             = this.$store.state.current_user_portal_caps.can_portal_access_start_date
            this.can_portal_access_end_date               = this.$store.state.current_user_portal_caps.can_portal_access_end_date
            this.can_portal_projects_manage               = this.$store.state.current_user_portal_caps.can_portal_projects_manage === '1'      
            this.can_portal_projects_see                  = this.$store.state.current_user_portal_caps.can_portal_projects_see === '1'
            this.can_portal_layers_manage                 = this.$store.state.current_user_portal_caps.can_portal_layers_manage === '1'
            this.can_portal_users_manage                  = this.$store.state.current_user_portal_caps.can_portal_users_manage === '1'
          }
        } else {
          const { can_portal_access_start_date }        = JSON.parse(JSON.stringify(this.data))
          this.can_portal_access_start_date             = can_portal_access_start_date
  
          const { can_portal_access_end_date }          = JSON.parse(JSON.stringify(this.data))
          this.can_portal_access_end_date               = can_portal_access_end_date
          
          const { can_portal_projects_manage }          = JSON.parse(JSON.stringify(this.data))
          this.can_portal_projects_manage               = can_portal_projects_manage === '1'
          
          const { can_portal_projects_see }             = JSON.parse(JSON.stringify(this.data))
          this.can_portal_projects_see                  = can_portal_projects_see === '1'
  
          const { can_portal_layers_manage }            = JSON.parse(JSON.stringify(this.data))
          this.can_portal_layers_manage                 = can_portal_layers_manage === '1'
  
          const { can_portal_users_manage }             = JSON.parse(JSON.stringify(this.data))
          this.can_portal_users_manage                  = can_portal_users_manage === '1'
        }

        this.new_can_portal_access_start_date         = this.can_portal_access_start_date
        this.new_can_portal_access_end_date           = this.can_portal_access_end_date
        this.new_can_portal_projects_manage           = this.can_portal_projects_manage
        this.new_can_portal_projects_see              = this.can_portal_projects_see
        this.new_can_portal_layers_manage             = this.can_portal_layers_manage
        this.new_can_portal_users_manage              = this.can_portal_users_manage

        this.capsLoaded = true
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.first_name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    currentUserProfile () {
      return this.user_id === this.$store.state.AppActiveUser.user_id
    },
    currentUserCanManageUsers () {
      if (this.capsLoaded) return this.$store.state.current_user_portal_caps.can_portal_users_manage === '1'
    },

    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    // ROYBON / Détails d'une activité
    // displayActivityPopup (id) {
    //   this.popupActivityId = id
    //   // this.loadActivityDetails()
    // },
    // disabled after security audit
    // // ROYBON / Détails d'une activité
    // loadActivityDetails () {
    //   const params = `sql_id=${this.popupActivityId}`
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUserActivityDetails}?${params}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       this.popupContent = response.data[0]
    //       this.popupActive = true
    //     })
    //     .catch((error)   => { console.log(error) })
    // },

    // ROYBON / Changement d'avatar
    avatarUploadSuccess () {
      this.incrementedAvatarVersion = this.incrementedAvatarVersion + 1 
      this.avatarUploadInProgress = false
      this.$vs.notify({
        color:'success',
        position:'top-right',
        title:this.$t('EditionFinished')
      })
      this.$store.dispatch('updateUserInfo', this.$store.state.AppActiveUser)
      logActivity.add('avatar_upload', 'success', this.user_id)
    },
    avatarUploadError () {
      this.avatarUploadInProgress = false
      this.$vs.notify({
        color: 'danger',
        title: this.$t('UploadError')
      })
    },
    avatarUploadStarted () {
      this.avatarUploadInProgress = true
    },

    // ROYBON / Capitalize first letter
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      const arr = value.split(' ')
      const capitalized_array = []
      arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalized_array.push(capitalized)
      })
      return capitalized_array.join(' ')
    },

    // ROYBON / Go to project
    gotoProject (project_slug) {
      this.isSidebarActiveLocal = false
      this.$router.push(`/project/${project_slug}`).catch(() => {})
    },

    // ROYBON / Vérifie si l'email n'est pas déjà utilisée par un autre user
    checkNewUserEmail () {
      let result = true
      const params = `user_email=${encodeURIComponent(this.new_user_email)}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserByEmail}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (null !== response.data) {
              if (!isNaN(response.data.user_id)) {
                if (response.data.user_id !== this.user_id) {
                  result = false  
                }
              }
            }
          } else {
            result = false
          }
          this.new_user_email_is_available = result 
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Préparation du formulaire
    prepareForm () {
      // check identity
      this.new_first_name = this.sanitizeTitle(this.new_first_name)
      this.new_last_name = this.sanitizeTitle(this.new_last_name)
      this.new_user_email = this.sanitizeEmail(this.new_user_email)

      if (this.new_first_name.toLowerCase() !== this.first_name || this.new_last_name.toLowerCase() !== this.last_name || this.new_user_email !== this.user_email) {
        this.btn_disabled = !(this.new_user_email_is_available && this.new_user_email_is_valid)
      } else {
        this.btn_disabled = true 
      }
      // check caps
      if (this.btn_disabled && this.new_user_email_is_available) {
        if (this.new_can_portal_access_start_date     || this.can_portal_access_start_date)   this.btn_disabled = false
        if (this.new_can_portal_access_end_date       || this.can_portal_access_end_date)     this.btn_disabled = false
        if (this.new_can_portal_projects_see          || this.can_portal_projects_see)        this.btn_disabled = false
        if (this.new_can_portal_projects_manage       || this.can_portal_projects_manage)     this.btn_disabled = false
        if (this.new_can_portal_layers_manage         || this.can_portal_layers_manage)       this.btn_disabled = false
        if (this.new_can_portal_users_manage          || this.can_portal_users_manage)        this.btn_disabled = false
      }
      // check user_lang
      if (this.new_user_lang !== this.user_lang) {
        this.btn_disabled = false
      }
    },
    // ROYBON / Fonction de nettoyage du titre
    sanitizeTitle (value) {
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      // value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      // value = value.replace(/[^0-9a-z_-]/gi, '¤')
      value = value.replace(/ /g, '¤')
      value = value.split('¤¤').join('¤')
      value = value.substring(0, 30)
      value = value.replace(/¤/gi, ' ')
      value = value.trim()
      return value
    },
    // ROYBON / Fonction de nettoyage de l'email
    sanitizeEmail (value) {
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/ /g, '')
      value = value.trim()
      value = value.toLowerCase()
      const emailParts = value.split(/@/)
      if (emailParts.length !== 2) {
        this.new_user_email_is_valid = false
      }
      const username = emailParts[0]
      const domain = emailParts[1]
      value = `${username}@${domain}`
      const re = /\S+@\S+\.\S+/
      if (re.test(value)) {
        const filter = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/
        this.new_user_email_is_valid = (String(value).search(filter) !== -1)
      } else {
        this.new_user_email_is_valid = false
      }
      return value
    },
    // ROYBON / Sauvegarde des modification globales
    editUserSave () {
      this.saveProfileUpdates()
      this.saveCapsUpdates()
    },
    // ROYBON / Annulation de l'édition
    editUserCancel () {
      this.isSidebarActiveLocal = false
      this.data = null
    },
    // ROYBON / Sauvegarde des modification de profil
    saveProfileUpdates () {
      /*
      let params = ''
      if (this.new_first_name.toLowerCase() !== this.first_name.toLowerCase())    params = `${params}&first_name=${encodeURIComponent(this.new_first_name)}`
      if (this.new_last_name.toLowerCase() !== this.last_name.toLowerCase())      params = `${params}&last_name=${encodeURIComponent(this.new_last_name)}`
      if (this.new_user_email.toLowerCase() !== this.user_email.toLowerCase())    params = `${params}&user_email=${encodeURIComponent(this.new_user_email)}`
      if (this.new_user_lang.toLowerCase() !== this.user_lang.toLowerCase())      params = `${params}&user_lang=${encodeURIComponent(this.new_user_lang)}`
      */
      let hasParams = false
      const params = new FormData()
      if (this.new_first_name.toLowerCase() !== this.first_name.toLowerCase()) {
        params.set('first_name', `${encodeURIComponent(this.new_first_name)}`)
        hasParams = true
      }
      if (this.new_last_name.toLowerCase() !== this.last_name.toLowerCase()) {
        params.set('last_name', `${encodeURIComponent(this.new_last_name)}`)
        hasParams = true
      }
      if (this.new_user_email.toLowerCase() !== this.user_email.toLowerCase()) {
        params.set('user_email', `${encodeURIComponent(this.new_user_email)}`)
        hasParams = true
      }
      if (this.new_user_lang.toLowerCase() !== this.user_lang.toLowerCase()) { 
        params.set('user_lang', `${encodeURIComponent(this.new_user_lang)}`)
        hasParams = true
      }
      if (hasParams) {
        //params = `user_id=${this.user_id}${  params}`
        const user_hash = md5(sha1(this.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
        params.set('user_id', `${this.user_id}`) 
        params.set('user_hash', user_hash)
        const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.updateUserIdentity}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('profileUpdated')
              })
              this.btn_disabled = true 
              this.first_name =   this.new_first_name
              this.last_name =    this.new_last_name
              this.user_email =   this.new_user_email
              this.user_lang =    this.new_user_lang
              
              // iuf current user : dispatch to store
              if (this.currentUserProfile) {
                this.$store.dispatch('updateUserInfo', {
                  displayName: `${this.first_name} ${ this.last_name}`,
                  email: this.user_email,
                  first_name: this.first_name,
                  last_name: this.last_name, 
                  user_email: this.user_email, 
                  user_lang: this.user_email
                })
              }
            } else {
            // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('profileNotUpdated')
              })
              this.btn_disabled = false 
            }
            if (this.currentUserProfile) {
              logActivity.add('profile_updated', 'success', this.user_id)
            } else {
              logActivity.add('profile_update', this.user_id)
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('profileNotUpdated')
            })
            this.btn_disabled = false 
          })
      }
      
    },
    // ROYBON / Sauvegarde des modification de capacités sur le portail
    saveCapsUpdates () {
      /*
      let params = ''
      if ((this.new_can_portal_access_start_date !== this.can_portal_access_start_date) && (this.new_can_portal_access_start_date.length > 0))    params = `${params}&can_portal_access_start_date=${encodeURIComponent(this.new_can_portal_access_start_date)}`
      if ((this.new_can_portal_access_end_date !== this.can_portal_access_end_date) && (this.new_can_portal_access_end_date.length > 0))          params = `${params}&can_portal_access_end_date=${encodeURIComponent(this.new_can_portal_access_end_date)}`
      if (this.new_can_portal_projects_see      !== this.can_portal_projects_see)         params = `${params}&can_portal_projects_see=${encodeURIComponent(this.new_can_portal_projects_see)}`
      if (this.new_can_portal_projects_manage   !== this.can_portal_projects_manage)      params = `${params}&can_portal_projects_manage=${encodeURIComponent(this.new_can_portal_projects_manage)}`
      if (this.new_can_portal_layers_manage     !== this.can_portal_layers_manage)        params = `${params}&can_portal_layers_manage=${encodeURIComponent(this.new_can_portal_layers_manage)}`
      if (this.new_can_portal_users_manage      !== this.can_portal_users_manage)         params = `${params}&can_portal_users_manage=${encodeURIComponent(this.new_can_portal_users_manage)}`
      */
      const params = new FormData()
      let hasParams = false
      if ((this.new_can_portal_access_start_date !== this.can_portal_access_start_date) && (this.new_can_portal_access_start_date.length > 0)) {
        params.set('can_portal_access_start_date', `${encodeURIComponent(this.new_can_portal_access_start_date)}`)
        hasParams = true
      }
      if ((this.new_can_portal_access_end_date !== this.can_portal_access_end_date) && (this.new_can_portal_access_end_date.length > 0)) {
        params.set('can_portal_access_end_date', `${encodeURIComponent(this.new_can_portal_access_end_date)}`)
        hasParams = true
      }
      if (this.new_can_portal_projects_see      !== this.can_portal_projects_see) {
        let rule = `${encodeURIComponent(this.new_can_portal_projects_see)}`
        rule = rule.replace(/true/g, '1').replace(/false/g, '0')
        params.set('can_portal_projects_see', rule)
        hasParams = true
      }
      if (this.new_can_portal_projects_manage   !== this.can_portal_projects_manage) {
        let rule = `${encodeURIComponent(this.new_can_portal_projects_manage)}`
        rule = rule.replace(/true/g, '1').replace(/false/g, '0')
        params.set('can_portal_projects_manage', rule)
        hasParams = true
      }
      if (this.new_can_portal_layers_manage     !== this.can_portal_layers_manage) {
        let rule = `${encodeURIComponent(this.new_can_portal_layers_manage)}`
        rule = rule.replace(/true/g, '1').replace(/false/g, '0')
        params.set('can_portal_layers_manage', rule)
        hasParams = true
      }
      if (this.new_can_portal_users_manage      !== this.can_portal_users_manage) {
        let rule = `${encodeURIComponent(this.new_can_portal_users_manage)}`
        rule = rule.replace(/true/g, '1').replace(/false/g, '0')
        params.set('can_portal_users_manage', rule)
        hasParams = true
      }

      if (hasParams) {
        //params = `portal_id=${this.$store.state.portal_id}&user_id=${this.user_id}${params}`
        //params = params.replace(/true/g, '1').replace(/false/g, '0')
        params.set('portal_id', `${this.$store.state.portal_id}`)
        params.set('user_id', `${this.user_id}`)
        const user_hash = md5(sha1(this.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
        params.set('user_hash', user_hash)
        const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.updatePortalUserCaps}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('profile_portal_caps_update', this.user_id)
              logActivity.add('profile_portal_caps_updated', 'success', this.user_id)
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('capsUpdated')
              })
              this.btn_disabled = true 
              this.can_portal_access_start_date =     this.new_can_portal_access_start_date
              this.can_portal_access_end_date =       this.new_can_portal_access_end_date
              this.can_portal_projects_see =          this.new_can_portal_projects_see
              this.can_portal_projects_manage =       this.new_can_portal_projects_manage
              this.can_portal_layers_manage =         this.new_can_portal_layers_manage
              this.can_portal_users_manage =          this.new_can_portal_users_manage
              this.prepareForm()
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('capsNotUpdated')
              })
              this.btn_disabled = false 
            }

          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('capsNotUpdated')
            })
            this.btn_disabled = false 
          })
      }

      
    },

    // ROYBON / Chargement de la liste des projets accessibles au user
    getUserProjects () {
      const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.user_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUserProjects}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          this.userProjects = response.data
          this.userProjectsLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },

    // disabled after security audit
    // // ROYBON / Chargement de la liste des activités récentes du user
    // getUserActivity () {
    //   const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.user_id}`
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUserActivity}?${params}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       console.log(response)
    //       this.userActivity = response.data
    //       this.userActivityLoaded = true
    //     })
    //     .catch((error)   => { console.log(error) })
    // },

    // ROYBON / Confirmation de suppression
    deleteUserConfirm () {
      this.isSidebarActiveLocal = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: `${this.capitalize(this.first_name)} ${this.capitalize(this.last_name)} : ${  this.$t('deleteUserConfirmMessage')}`,
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.deleteUserAction
      })
    },
    // ROYBON / Action de suppression
    deleteUserAction () {
      //const params = `user_id=${this.user_id}&portal_id=${this.$store.state.portal_id}`
      const params = new FormData()
      params.set('user_id', `${this.user_id}`)
      params.set('portal_id', `${this.$store.state.portal_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.deletePortalUserAllocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            logActivity.add('portal_user_removal', this.user_id)
            logActivity.add('portal_user_removed', 'success', this.user_id)
            // success
            this.$vs.notify({
              color:'success',
              title:this.$t('userDeletionSuccess'),
              text:this.$t('deletedUser')
            })
            this.$emit('closeSidebar')
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('userDeletionError'),
              text:this.$t('notDeletedUser')
            })
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('userDeletionError'),
            text:this.$t('notDeletedUser')
          })
        })
    },

    // ROYBON / Confirmation de retrait du user du projet
    deleteProjectUserConfirm (project_id, user_id) {
      console.log(`retrait de user_id ${user_id} sur project_id ${project_id}`)
      this.projectWhereToRemoveUser = project_id
      this.isSidebarActiveLocal = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectUserRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.deleteProjectUserAction
      })
    },
    // ROYBON / Action de suppression
    deleteProjectUserAction () {
      //const params = `user_id=${this.user_id}&project_id=${this.projectWhereToRemoveUser}`
      const params = new FormData()
      params.set('user_id', `${this.user_id}`)
      params.set('project_id', `${this.projectWhereToRemoveUser}`)      
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectUser}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            logActivity.add('project_user_removal', this.user_id, null, this.projectWhereToRemoveUser)
            logActivity.add('project_user_removed', 'success', this.user_id, null, this.projectWhereToRemoveUser)
            // success
            this.$vs.notify({
              color: 'success',
              title: this.$t('ConfirmedRemoval'),
              text: this.$t('ProjectUserRemovalConfirmationMessage')
            })
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          }
          this.userProjectsLoaded = false
          this.getUserProjects()
          this.$emit('closeSidebar')
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('FailedRemoval')
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}

.project_title {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.sidebar-tooltip {
  z-index: 999999;
}
.vs-tooltip {
  z-index: 999999;
}
.scrollable {
  max-height: 300px;
}

</style>